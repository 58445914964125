.DateInput {
  background-color: $main-color;
  color: $secondary-color;
  font-weight: bold;

  @media (max-width: $breakpoint-tablet) {
    height: 36px;
    font-size: 12px;
    padding: 0;
  }

  @media (max-width: $breakpoint-phone) {
    width: 110px;
  }
}

.DateRangePickerInput {
  background-color: $main-color;
  color: $secondary-color;
  border: 1px solid black;

  @media (max-width: $breakpoint-tablet) {
    height: 38px;
    font-size: 12px;
    padding: 0;
  }
}

.DateInput_input {
  background-color: $main-color;
  color: $secondary-color;
  font-weight: bold;

  @media (max-width: $breakpoint-tablet) {
    height: 36px;
    font-size: 12px;
    padding: 0;
  }
}
.DateInput_input__focused {
  background-color: $main-color;
  color: $secondary-color;
  font-weight: bold;
}

.DateRangePickerInput_arrow {
  fill: $secondary-color !important;
}

.SingleDatePickerInput__withBorder {
  border: 1px solid black;
}
.CalendarDay__selected_span {
  background: $main-color; //background
  color: white; //text
  border: 2px solid; //default styles include a border
}
.CalendarDay__selected {
  background: $secondary-color;
  color: white;
}

.CalendarDay__selected:hover {
  background: $secondary-color;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: $main-color;
  color: white;
}
