.content-container {
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 $m-size;
  @media (max-width: $breakpoint-tablet) {
    max-width: 60rem;
  }
  @media (max-width: $breakpoint-phone) {
    max-width: 50rem;
  }
}
