.loader--container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader--image {
  height: 30rem;
  width: 30rem;
}
