.form__error {
  margin: 0 0 $m-size 0;
  font-style: italic;
}

.form {
  display: flex;
  flex-direction: column;
  > * {
    margin: 0 0 $m-size 0;
  }
}

.form-button {
  background-color: $main-color;
  border: none;
  color: $secondary-color;
  text-align: center;
  text-decoration: none;
  font-size: $m-size;
  padding: 15px;
  width: auto;
  display: inline-block;
  width: 160px;
  border-radius: 6px;

  &:hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }

  @media (max-width: $breakpoint-tablet) {
    font-size: $s-size;
    width: 120px;
    padding: 12px;
  }

  @media (max-width: $breakpoint-phone) {
    font-size: $m-size;
    width: 150px;
    padding: 12px;
  }
}
