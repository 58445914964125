* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  min-height: 100vh;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: $m-size;
  line-height: 1.6;
  background: $secondary-color;
}

h1 {
  font-size: 2.2rem;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

a:visited {
  text-decoration: none;
}

.is-active {
  color: none;
}

.text-help {
  text-align: center;
  margin-top: 50px;
  font-size: $m2-size;
  @media (max-width: $breakpoint-tablet) {
    font-size: $m-size;
  }
}
