.show-on-mobile {
  text-align: center;
  @media (min-width: $breakpoint-phone + 1px) {
    display: none;
  }
}

.show-on-desktop {
  @media (max-width: $breakpoint-phone) {
    display: none;
  }
}
