.list-header {
  background: $main-color;
  border: 1px solid black;
  color: $secondary-color;
  display: flex;
  justify-content: space-between;
  padding: $s-size $m-size;

  @media (max-width: $breakpoint-tablet) {
    padding: $s-size;
    font-size: 1.2rem;
  }

  @media (max-width: $breakpoint-phone) {
    font-size: 1.4rem;
  }
}

.list-item {
  display: flex;
  border: 1px solid black;
  border-top: none;
  padding: $m-size;
  text-decoration: none;
  justify-content: space-between;
  color: black;
  &:hover {
    background: #5a5e6b;
    color: $secondary-color;
  }
  > * {
    flex: 1 1 0;
  }
  @media (max-width: $breakpoint-tablet) {
    padding: 8px;
    font-size: 1.2rem;
  }
  @media (max-width: $breakpoint-phone) {
    font-size: 1.4rem;
  }
}

.lits-item__category {
  text-align: center;
  @media (max-width: $breakpoint-tablet) {
    padding-top: 10px;
    font-size: 1.2rem;
  }
  @media (max-width: $breakpoint-phone) {
    font-size: 1.4rem;
  }
}

.lits-item__amount {
  text-align: right;
  @media (max-width: $breakpoint-tablet) {
    padding: 10px;
    font-size: 1.2rem;
  }
  @media (max-width: $breakpoint-phone) {
    font-size: 1.4rem;
  }
}

.lits-item__title {
  margin: 0;
  word-break: break-all;
  @media (max-width: $breakpoint-tablet) {
    padding: 0;
    font-size: 1.2rem;
  }
  @media (max-width: $breakpoint-phone) {
    font-size: 1.4rem;
  }
}

.lits-item__message {
  align-items: center;
  justify-content: center;
  padding: $m-size;
  &:hover {
    background: none;
  }
  > * {
    text-align: center;
  }
}
