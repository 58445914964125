.box-layout {
  height: 100vh;
  width: 100vw;
  background-size: cover;
  align-items: center;
  display: flex;
  justify-content: center;
  @media (max-width: $breakpoint-tablet) {
    align-items: flex-start;
    padding-top: 10px;
  }

  @media (max-width: $breakpoint-phone) {
    padding: 0;
  }
}

.box-layout__box {
  background: $main-color;
  border-radius: 8px;
  padding: $l-size;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px 5px;

  @media (max-width: $breakpoint-phone) {
    border-radius: 0px;
    padding: $s-size $m-size $xl-size $m-size;
    gap: 3px;
  }
}

.box-main_logo {
  margin: $m-size 0;

  @media (max-width: $breakpoint-phone) {
    margin: $s-size 0;
  }
}

.box-layout__title {
  margin: $s-size 0;
  text-align: center;
  color: $secondary-color;
}

.box-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 80%;
  margin-top: $xl-size;

  @media (max-width: $breakpoint-phone) {
    margin: $m-size 0;
  }
}
