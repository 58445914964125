//size
$s-size: 1.2rem;
$m-size: 1.6rem;
$m2-size: 2.2rem;
$l-mobile-size: 2.4rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;

//color
$main-color: #4a4d57;
$secondary-color: #27ad63;

//media
$breakpoint-big-tablet: 1025px;
$breakpoint-tablet: 769px;
$breakpoint-phone: 550px;
